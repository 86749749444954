<template>
  <octo-table
    show-filters
    :action="endpoints.DATATABLES.payments"
    :fields="fields"
    :filters="filters"
    :name="name"
    :show-id="true"
    :order-by-items="['deadline', 'payment_date']"
    enable-export
    @onResetFilters="filters = {}"
  >
    <template v-slot:deadline="data">
      <div class="small">{{ data.row.deadline | date }}</div>
    </template>

    <template v-slot:name="data">
      {{ data.row.surname}} {{data.row.name }}
    </template>

    <template v-slot:amount="data">
      {{ data.row.amount | euro }}
    </template>

    <template v-slot:confirmed_date="data">
      {{ data.row.confirmed_date | date }}
    </template>

    <template v-slot:date_out="data">
      {{ data.row.date_out | date }}
    </template>

    <template v-slot:competence_date="data">
      {{ data.row.competence_date | date }}
    </template>

    <template v-slot:classroom_info="data">
      <div class="small">{{ data.row.classroom_info }}</div>
    </template>

    <template v-slot:accounting_status="data">
      <badge :type="dealAccountingStatuses[data.row.accounting_status].color" class="text-uppercase">
        {{ $t('deal.accounting_statuses.' + data.row.accounting_status) }}
      </badge>
    </template>

    <template v-slot:deal_status="data">
      <badge  v-if="data.row.deal_status"
              :style="{backgroundColor: schedulerService.getDealStatusColor(data.row.deal_status)}" class="text-uppercase">
        {{ $t('deal.status.' + data.row.deal_status) }}
      </badge>
    </template>

    <template v-slot:student_status="data">
      <badge v-if="data.row.student_status" :type="data.row.student_status | studentStatusColor" class="text-uppercase">
        {{ $t('didactics.student_status.' + data.row.student_status) }}
      </badge>
    </template>

    <template v-slot:status="data">
      <badge v-if="data.row.status" :type="paymentStatusConst[data.row.status].color" class="text-uppercase">
        {{ $t('deal.payment_statuses.' + data.row.status) }}
      </badge>
    </template>

    <template v-slot:detail="data">
      <div class="small text-uppercase" v-if="data.row.reason">
        <label-theme-component>{{$t('datatable.reason')}}: </label-theme-component> {{ $t('deal.payment_reasons.' + data.row.reason) }}
      </div>
      <div class="small text-uppercase" v-if="data.row.payment_date">
        <label-theme-component>{{$t('datatable.payment_date')}}: </label-theme-component> {{ data.row.payment_date | date }}
      </div>
      <div class="small text-uppercase" v-if="data.row.payment_type">
        <label-theme-component>{{$t('datatable.payment_type')}}: </label-theme-component> {{ $t('deal.payment_types.' + data.row.payment_type) }}
      </div>


    </template>

    <template v-slot:actions="data">
      <div class="d-flex justify-content-end">
        <el-tooltip :content="$t('deal.show_deal')" :open-delay="300" placement="top">
          <base-button class="mx-1"
                       size="sm"
                       link
                       icon
                       @click="$router.push({
                         name: 'post_selling.show',
                         params: {id: data.row.deal_id}
                       })">
            <octo-icon icon="right-arrow"/>
          </base-button>
        </el-tooltip>
      </div>
    </template>


    <template slot="custom-filters">
      <div class="col-12 col-md-4">
        <base-input :label="$t('fields.status')">
          <el-select
            multiple
            clearable
            class="select-default text-uppercase"
            :placeholder="$t('fields.choose')"
            v-model="filters.statuses"
          >
            <el-option
              v-for="status in paymentStatusConst"
              class="select-default text-uppercase"
              :value="status.value"
              :label="$t('deal.payment_statuses.' + status.value)"
              :key="status.value"
            />
          </el-select>
        </base-input>
      </div>

      <div class="col-12 col-md-4">
        <base-input :label="$t('datatable.accounting_status')">
          <el-select
            multiple
            filterable
            class="select-default text-uppercase"
            :placeholder="$t('common.type_to_search')"
            v-model="filters.accountingStatuses"
          >
            <el-option
              v-for="status in dealAccountingStatuses"
              class="select-default text-uppercase"
              :value="status.value"
              :label="$t('deal.accounting_statuses.' + status.value)"
              :key="status.value"
            />
          </el-select>
        </base-input>
      </div>

      <div class="col-12 col-md-4">
        <base-input :label="$t('fields.reason')">
          <el-select
            multiple
            clearable
            class="select-default text-uppercase"
            :placeholder="$t('fields.choose')"
            v-model="filters.reasons"
          >
            <el-option
              v-for="reason in paymentReasonConst"
              class="select-default text-uppercase"
              :value="reason.value"
              :label="$t('deal.payment_reasons.' + reason.value)"
              :key="reason.value"
            />
          </el-select>
        </base-input>
      </div>

      <div class="col-12 col-md-4">
        <base-input :label="$t('fields.deadline')">
          <el-date-picker
            v-model="filters.deadlineRange"
            type="daterange"
            align="right"
            unlink-panels
            format="dd/MM/yyyy"
            value-format="yyyy-MM-dd"
            range-separator="-"
            :start-placeholder="$t('fields.date_start')"
            :end-placeholder="$t('fields.date_end')"
            :picker-options="{firstDayOfWeek: 1}"
          />
        </base-input>
      </div>

      <div class="col-12 col-md-4">
        <base-input :label="$t('datatable.payment_type')">
          <el-select
            multiple
            filterable
            class="select-default text-uppercase"
            :placeholder="$t('common.type_to_search')"
            v-model="filters.paymentTypes"
          >
            <el-option
              v-for="(option, index) in paymentOptions"
              v-bind:key="`payment-type-${index}`"
              class="select-default text-uppercase"
              :value="option"
              :label="$t('deal.payment_types.' + option)"
            >
            </el-option>
          </el-select>
        </base-input>
      </div>

      <div class="col-12 col-md-4">
        <base-input :label="$t('deal.date_end_contract')">
          <el-date-picker
            v-model="filters.dateOutRange"
            type="daterange"
            align="right"
            unlink-panels
            format="dd/MM/yyyy"
            value-format="yyyy-MM-dd"
            range-separator="-"
            :start-placeholder="$t('fields.date_start')"
            :end-placeholder="$t('fields.date_end')"
            :picker-options="{firstDayOfWeek: 1}"
          />
        </base-input>
      </div>

      <div class="col-12 col-md-2">
        <base-input :label="$t('datatable.date_competence')">
          <el-select
            clearable
            class="select-default text-uppercase"
            :placeholder="$t('fields.choose')"
            v-model="filters.competence_date"
          >
            <el-option
              v-for="option in [
                  { value: 'ok', label: $t('common.yes')},
                  { value: 'ko', label: $t('common.no')},
                ]"
              class="select-default text-uppercase"
              :value="option.value"
              :label="option.label"
              :key="option.value"
            />
          </el-select>
        </base-input>
      </div>

      <div class="col-12 col-md-2">
        <base-input :label="$t('datatable.confirmed_date')">
          <el-select
            clearable
            class="select-default text-uppercase"
            :placeholder="$t('fields.choose')"
            v-model="filters.confirmed_date"
          >
            <el-option
              v-for="option in [
                  { value: 'ok', label: $t('common.yes')},
                  { value: 'ko', label: $t('common.no')},
                ]"
              class="select-default text-uppercase"
              :value="option.value"
              :label="option.label"
              :key="option.value"
            />
          </el-select>
        </base-input>
      </div>

      <div class="col-12 col-md-3">
        <base-input :label="$t('datatable.amount')" v-model="filters.amount"/>
      </div>
    </template>


  </octo-table>
</template>


<script>
import OctoTable from "@/components/octo-table/OctoTable.vue";
import {endpoints} from "@/routes/endpoints";
import {mapGetters} from "vuex";
import {Select, Option, DatePicker} from "element-ui";
import PaymentStatusConst from "../../Deals/resources/paymentStatusConst";
import PaymentReasonConst from "../../Deals/resources/paymentReasonConst";
import Badge from "@/components/Badge.vue";
import LabelThemeComponent from "@/components/LabelThemeComponent.vue";
import OctoIcon from "../../../components/octo-icon/OctoIcon.vue";
import DealPaymentTypes from "@/pages/Deals/resources/dealPaymentTypes";
import DealAccountingStatus from "@/pages/Deals/resources/dealAccountingStatus";
import {schedulerService} from "@/util/scheduler-service";

export default {
  name: "PaymentDatatable",
  components: {

    OctoIcon,
    LabelThemeComponent,
    Badge,
    OctoTable,
    [Select.name]: Select,
    [Option.name]: Option,
    [DatePicker.name]: DatePicker
  },
  data() {
    return {
      name: 'PaymentDataTable',
      endpoints: endpoints,
      filters: {},
      fields: [
        {prop: 'deadline', label: 'deadline', slot: true, align: 'center', width:120, fixed:true},
        {prop: 'name', label: 'name', slot: true, align: 'left', fixed:true, width: 250},
        {prop: 'classroom_info', label: 'classroom_info', slot: true, align: 'left',width:230 },
        {prop: 'date_out', label: 'date_out', slot: true, align: 'center',width:150 },
        {prop: 'competence_date', label: 'competence_date', slot: true, align: 'center',width:150 },
        {prop: 'confirmed_date', label: 'confirmed_date', slot: true, align: 'center',width:150 },
        {prop: 'status', label: 'status', slot: true, align: 'center', width:180},
        {prop: 'deal_status', label: 'deal_status', slot: true, align: 'center', minWidth: 180},
        {prop: 'accounting_status', label: 'accounting_status', slot: true, align: 'center', width:150},
        {prop: 'student_status', label: 'didactic_status', slot: true, align: 'center', width:150},
        {prop: 'amount', label: 'amount', slot: true, align: 'center', width:120},
        {prop: 'detail', label: 'detail', slot: true, align: 'left', width: 250},
        {prop: 'actions', label: 'actions', width: 100, slot: true, fixed:'right'},
      ],
      paymentStatusConst: PaymentStatusConst,
      paymentReasonConst: PaymentReasonConst,
      paymentOptions: this.$_.map(DealPaymentTypes, (item) => item.value),
      dealAccountingStatuses: DealAccountingStatus,
      schedulerService: schedulerService,
    }
  },
  beforeMount() {
    this.filters = this.getFilters(this.name);
  },
  computed: {
    ...mapGetters({
      getFilters: 'common/datatableFilters',
    })
  }
}
</script>

<style scoped>

</style>
