<template>
  <div class="row">
    <div class="col-12">
      <payment-datatable/>
    </div>
  </div>
</template>

<script>
import PaymentDatatable from "@/pages/PostSelling/datatables/PaymentDatatable.vue";
export default {
  name: "IndexPaymentPage",
  components: {PaymentDatatable}
}
</script>

<style scoped>

</style>
